@charset "UTF-8";

@import './formio-bootstrap.scss';

@import '~formiojs/dist/formio.full.min.css';

@import '~met-formio/dist/met-formio-components.css';

$btn-primary-main: #ffc107;
$btn-primary-light: #ffe082;
$btn-primary-dark: #ffab00;
$text-primary: #494949;

.formio {
    font-family: 'BCSans', 'Noto Sans', Verdana, Arial, sans-serif;
}
.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    max-width: 100vw;
}
.container {
    padding-left: 0;
    padding-right: 0;
    height: 100%;
}
.task-container {
    height: 100% !important;
}
.logo {
    height: 1.9em;
}
h4 {
    font-size: 1.2rem;
}
#main {
    /* margin-top: 2px;*/
    min-height: 85vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
        0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.loader-container {
    display: flex;
    height: 80vh;
    justify-content: center;
    align-items: center;
}
.loader {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.input-group .input-group-addon {
    position: absolute;
    right: 5px;
    top: 7px;
}
.input-group .radio-inline {
    margin-left: 20px;
}
.input-group .radio-inline label {
    margin-right: 2rem;
}
.well {
    border: 1px solid #eeeeee;
    padding: 1rem;
    background-color: #f8f8f8bf;
}
.formio-component-panel {
    border: 1px solid #eeeeee;
}
.formio-component-panel .panel-heading {
    background-color: #eeeeee;
    padding: 10px 0 10px 10px;
}
.formio-component-panel .panel-heading .panel-title i {
    font-size: 12px;
}
.formio-component-panel .panel-body {
    padding: 1.5rem 2rem 1.5rem 2rem;
}
.page-item.active .page-link {
    background-color: #003366 !important;
    border-color: #56595d !important;
}
.bg-default {
    background-color: #38598a;
    border: #003366;
    color: white !important;
}
.text-muted {
    color: #868e96 !important;
}
i.fa.fa-question-circle.text-muted {
    color: #003366 !important;
}
.panel-heading {
    background-color: #003366;
    border: #003366;
    color: white !important;
    padding: 0.75rem 1.25rem;
    margin-bottom: 8px;
}
.formio-error-wrapper,
.has-error {
    color: red;
    background-color: #fff;
    border-color: red;
}
.formio-error-wrapper label,
.has-error label {
    color: red;
}
.formio-errors .error {
    color: red;
}
.is-invalid {
    background-color: #ffd1d1;
}
.nav-dropdown .dropdown-menu {
    width: 15rem;
    height: 14rem;
    padding: 15px 20px 0 20px;
    right: 0;
    top: 2rem;
    left: auto;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.nav-dropdown .dropdown-toggle {
    color: white !important;
}
.nav-dropdown .dropdown-toggle a {
    text-decoration: none;
}
.nav-dropdown .userIcon {
    padding: 0 0 0 0;
    font-size: 2rem;
    float: right;
    color: #003366;
}
.nav-dropdown :hover {
    text-decoration: none !important;
}
.nav-custom-tab {
    padding-left: 60px !important;
}
.main-nav {
    text-decoration: none !important;
    color: #000 !important;
    font-size: 20px !important;
}
.nav-user-role {
    color: #003366;
    font-size: 15px;
    font-weight: bold;
    padding: 0 0;
}
.nav-user-email {
    font-size: 14px;
    padding: 0 0;
    color: #373a3c;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.nav-user-name {
    font-size: 20px;
    padding: 0 0;
    font-weight: 600;
    color: #373a3c;
}
.nav-logout {
    cursor: pointer;
    padding: 0 0;
    font-size: 16px;
}
.nav-tabs .nav-link.active {
    color: #4d4d4d;
    background-color: #f5f5f5;
    margin-bottom: 0;
}
.nav-link {
    text-decoration: none;
}
.active {
    background-color: #fff;
}
.active-tab {
    background-color: transparent;
    filter: invert(36%) sepia(38%) saturate(7152%) hue-rotate(227deg) brightness(101%) contrast(98%);
}
.active-tab-dropdown > #dashboard-dropdown {
    background-color: transparent;
    filter: invert(36%) sepia(38%) saturate(7152%) hue-rotate(227deg) brightness(101%) contrast(98%);
}
.active-tab-dropdown > #task-dropdown {
    background-color: transparent;
    filter: invert(36%) sepia(38%) saturate(7152%) hue-rotate(227deg) brightness(101%) contrast(98%);
}
.dropdown-item:hover {
    filter: none;
    background-color: #e9ecef;
}
.selected-tag {
    color: #003366 !important;
    border-bottom: 1px solid #003366;
    background-color: transparent;
}
.lbl-logout {
    font-size: 16px;
    color: #373a3c;
    margin-left: 10px;
    cursor: pointer;
}
.dropdown-menu:before {
    position: absolute;
    top: -7px;
    right: 9px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
}
.dropdown-menu:after {
    position: absolute;
    top: -6px;
    right: 10px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: '';
}
.taskDropdown .dropdown-menu::before {
    left: 10%;
    right: 100%;
}
.taskDropdown .dasboard-icon-dropdown {
    margin-bottom: 5px;
}
.taskDropdown .task-dropdown-icon {
    margin-bottom: 1px;
}
.taskDropdown .applications-icon-header {
    width: 23px;
    height: 23px;
    margin-bottom: 5px;
}
.taskDropdown .dropdown-menu::after {
    left: 10%;
    right: 100%;
}
.custom-profile {
    position: absolute;
    margin-left: 220px;
    padding-bottom: 10px;
    z-index: 10;
}
.d-md-none .dropup,
.d-md-none .dropright,
.d-md-none .dropdown,
.d-md-none .dropleft {
    position: initial;
}
.d-md-none .nav-dropdown .dropdown-menu {
    position: absolute;
}
.d-md-none .dropdown-menu:before {
    position: absolute;
    top: -6px;
    right: 4px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
}
.d-md-none .dropdown-menu:after {
    position: absolute;
    top: -5px;
    right: 5px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: '';
}
.lbl-app-nanme {
    font-size: 20px;
    color: #ffff;
    margin-bottom: 0;
    font-weight: bold;
    margin-left: 20px;
}
.app-name {
    color: #fcba19;
    margin: 0;
}
.nav-icons {
    vertical-align: text-top;
    margin-right: 3px;
}

.table-bordered thead th,
.table-bordered thead td {
    background-color: #f2f2f2 !important;
}
.navbar-dark.navbar-toggler {
    border-color: white;
}
.main-header {
    margin-left: 15px !important;
    display: flex;
    margin-top: 10px;
    margin-bottom: 25px;
    height: 30px;
    width: 100%;
}
.back-icon {
    display: flex;
}
.sub-container {
    margin-left: 15px;
}
.task-head {
    font-weight: bold;
    font-size: 30px;
    margin-left: 10px !important;
}
.icon-wp-forms {
    height: 30px;
}
.task-head-icons {
    height: 30px;
    width: 30px;
}
.task-head-details {
    font-weight: bold;
    font-size: 30px;
    margin-left: 10px !important;
}
.forms-text {
    vertical-align: middle;
    margin-left: 10px;
}
.btn-primary {
    background-color: $btn-primary-main;
    border-color: $btn-primary-main;
    color: $text-primary;
}
.btn-primary:hover {
    background-color: $btn-primary-light;
    border-color: $btn-primary-light;
    color: $text-primary;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    background-color: $btn-primary-dark;
    border-color: $btn-primary-dark;
    color: $text-primary;
}
.btn-right {
    float: right;
    margin-left: auto;
}
.btn-center {
    float: right;
    margin-left: auto;
}
.btn.btn-link:focus,
.btn.btn-link.focus {
    box-shadow: none;
}
.div-center {
    align-self: center;
}

.navbar-brand {
    align-items: center;
}
.navbar-brand .img-fluid,
.navbar-brand .img-thumbnail {
    max-width: 100%;
    height: 100%;
}
select option:hover {
    box-shadow: 0 0 10px 100px #000 inset;
}
.modal-dialog {
    margin: 8.75rem auto !important;
}
#sidebar {
    background: #fff;
}
.content {
    background-color: #f8f8f8;
}
#sidebar ul li.active > a,
#sidebar a[aria-expanded='true'] {
    background: #fff;
}
header nav {
    border-bottom: #fff;
}
.topheading-border-bottom {
    z-index: 2000;
    border-bottom: 2px solid #003366 !important;
    padding: 0 0.5rem !important;
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
        0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.rounded-circle {
    border-radius: 50% !important;
    border: 2px solid lightgrey;
    height: 40px;
    width: 40px;
}
.custom-app-name {
    font-size: 30px;
    font-weight: 600;
    /*margin-left: -15px!important;*/
    color: black;
}
div[disabled] {
    pointer-events: none;
    opacity: 0.7;
}
.hide-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.hide-scroll::-webkit-scrollbar {
    display: none;
}
.classApplicationId {
    width: 15%;
}
.classApplicationName {
    width: 20%;
}

.col-form-label {
    font-weight: bold;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-content {
    font-family: 'BCSans', 'Noto Sans', Verdana, Arial, sans-serif;
    font-size: 1.1em;
    line-height: 1.5em;
}

.title {
    padding-bottom: 30px;
}

.tippy-box {
    position: relative;
    background-color: #003366;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.4;
    white-space: normal;
    outline: 0;
    transition-property: transform, visibility, opacity;
    font-weight: 500;
}

.tippy-content {
    position: relative;
    padding: 5px 9px;
    z-index: 1;
}

.tippy-arrow {
    width: 16px;
    height: 16px;
    color: #003366;
}

.tippy-arrow::before {
    content: '';
    position: absolute;
    border-color: transparent;
    border-right-color: transparent;
    border-style: solid;
}

.tippy-box[data-placement^='right'] > .tippy-arrow {
    left: 0;
}

.tippy-box[data-placement^='right'] > .tippy-arrow::before {
    left: -7px;
    border-width: 8px 8px 8px 0;
    border-right-color: initial;
    transform-origin: center right;
}

// Hide formio managed success messages so they can be handled in MET
.alert-success {
    display: none;
}

.BC-Gov-SecondaryButton {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.125rem;
    text-transform: none;
    font-family: BCSans, 'Noto Sans', Verdana, Arial, sans-serif;
    min-width: 64px;
    padding: 5px 15px;
    height: 40px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(0, 51, 102);
    line-height: 1.1rem;
    border: 2px solid rgb(0, 51, 102);
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
}

.BC-Gov-SecondaryButton:hover {
    opacity: 0.8;
    text-decoration: underline;
    background-color: #003366;
    color: #ffffff;
}

.BC-Gov-PrimaryButton {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.125rem;
    text-transform: none;
    font-family: BCSans, 'Noto Sans', Verdana, Arial, sans-serif;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    height: 40px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(0, 51, 102);
    color: rgb(255, 255, 255);
    line-height: 1.1rem;
}

.BC-Gov-PrimaryButton:hover {
    text-decoration: underline;
    opacity: 0.8;
}

.btn-wizard-nav-next {
    @extend .BC-Gov-PrimaryButton;
}

.btn-wizard-nav-next:hover {
    @extend .BC-Gov-PrimaryButton;
    @extend .BC-Gov-PrimaryButton:hover;
}

.btn-wizard-nav-cancel {
    @extend .BC-Gov-SecondaryButton;
}

.btn-wizard-nav-cancel:hover {
    @extend .BC-Gov-SecondaryButton;
    @extend .BC-Gov-SecondaryButton:hover;
}

.btn-wizard-nav-previous {
    @extend .BC-Gov-SecondaryButton;
}

.btn-wizard-nav-previous:hover {
    @extend .BC-Gov-SecondaryButton;
    @extend .BC-Gov-SecondaryButton:hover;
}

.btn-wizard-nav-submit {
    @extend .BC-Gov-PrimaryButton;
}

.btn-wizard-nav-submit:hover {
    @extend .BC-Gov-PrimaryButton;
    @extend .BC-Gov-PrimaryButton:hover;
}

.pagination {
    display: none;
}
.formio-component-breadcrumb {
    display: none;
}

.formio-component-breadcrumbClickable {
    display: none;
}

.choices__list.choices__list--dropdown {
    overflow-y: auto !important;
    max-height: 200px !important;
}

.choices__list--dropdown .choices__list {
    overflow: visible;
}

.formio-component-simplesurvey {
    overflow-y: scroll;
}
